/* global process __webpack_public_path__ */
import 'core-js/es'
import { setEnv } from 'swisscard/helpers/env'
import DataHandler from '@atpoint/atpjs/data_handler'
import initial from '@merchant/stores/initial'

const urlToOrigin = (href: string): string => {
  const a = document.createElement('a')
  a.href = href
  return a.origin || `${a.protocol}//${a.host}`
}

// select element from data-id on script tag or given selector
let currentScript = document.currentScript
if (!currentScript) {
  // workaround for IE
  const scripts = document.getElementsByTagName('script')
  currentScript = scripts[scripts.length - 1]
}

const selectorApi = currentScript.dataset
  ? currentScript.dataset.api || ''
  : currentScript.getAttribute('data-api') || ''

setEnv('API_HOST', selectorApi)

setEnv(
  'APP_ENVIRONMENT',
  (currentScript.dataset
    ? currentScript.dataset.appEnvironment
    : currentScript.getAttribute('data-app-environment')) as string
)

// @ts-ignore
// eslint-disable-next-line
__webpack_public_path__ = urlToOrigin(currentScript.src) + __webpack_public_path__

// another fallback
const selector = currentScript.dataset ? currentScript.dataset.selector : currentScript.getAttribute('data-selector')
let rootElement = document.querySelector(selector || '.atp-widget-merchant')

// create new element if nones exists
if (!rootElement) {
  rootElement = document.createElement('div')
  if (currentScript.parentNode) {
    currentScript.parentNode.insertBefore(rootElement, currentScript.nextSibling)
  } else {
    console.error('cannot insert rootElement because parentNode is null')
  }
}

const element = document.documentElement

// @ts-ignore
import('@merchant').then((module) => {
  const { initializeApp } = module
  const locale = ((element && element.lang) || 'de-CH').substring(0, 2)
  const data = initial.set('locale', locale)

  if (rootElement) {
    // @ts-ignore
    initializeApp(rootElement, DataHandler.instance(data), 'amex')
  } else {
    console.log('root element not found, aborting init')
  }
})

declare global {
  interface Window {
    atpWidgetMERCHANT: any
    dataLayer: any // For google tag manager
  }
}
window.atpWidgetMERCHANT = window.atpWidgetMERCHANT || []
window.atpWidgetMERCHANT.push({
  name: 'merchant',
  version: '0.0.1',
})
window.dataLayer = window.dataLayer || []
