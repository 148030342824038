/* global process */

let env = Object.freeze({ ...process.env })

export const setEnv = (key: string, value: string) => {
  env = Object.freeze({ ...env, [key]: value })
}

export const getEnv = (key: string): string | undefined => {
  return env[key]
}
